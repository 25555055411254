import React from "react"
import { Link } from "gatsby"
import "./Footer.scss"

const Footer = () =>

    <div id="footer">
        <footer>
            <div className="container content" id="mq-large">
                <div className="row">
                    <div className="col-md-6 col-sm-6">
                        <div className="row">    
                            <div className="col-md-5 col-sm-5">
                                <div className="category-title">Company</div>
                                <ul>
                                    <li><Link to="/about">About Us</Link></li>
                                    {/* <li>Careers</li> */}
                                </ul>
                            </div>
                            <div className="col-md-7 col-sm-7">
                                <div className="category-title">What we do</div>
                                <ul>
                                    <li><Link to="/personal-discovery">Personal Discovery</Link></li>
                                    <li><Link to="/people-analytics">People Analytics for Companies</Link></li>
                                    <li><Link to="/customized-solutions">Customized Solutions</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 col-sm-3">
                        <div className="category-title">Our address</div>
                        <ul>
                            <li>the Hive Lavender<br/>Level 6, Vanguard Building<br/>1 Kallang Junction, Singapore 339263</li>
                        </ul>
                    </div>
                    <div className="col-md-3 col-sm-3">
                        <div className="category-title">Connect with us</div>
                        <ul>
                            <li><a href="https://www.linkedin.com/company/mercurics/" target="_blank" rel="noopener noreferrer"><i className="icon-linkedin"></i>LinkedIn</a></li>
                            <li><b><a href="mailto:hello@mercurics.com" target="_blank" rel="noopener noreferrer"><i className="icon-mail"></i>hello@mercurics.com</a></b></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="container content" id="mq-medium">
                <div className="row">
                    <div className="col-md-6 col-sm-6 footer-segment">
                        <div className="category-title">Company</div>
                        <ul>
                            <li><Link to="/about">About Us</Link></li>
                            {/* <li>Careers</li> */}
                        </ul>
                    </div>
                    <div className="col-md-6 col-sm-6 footer-segment">
                        <div className="category-title">What we do</div>
                        <ul>
                            <li><Link to="/personal-discovery">Personal Discovery</Link></li>
                            <li><Link to="/people-analytics">People Analytics for Companies</Link></li>
                            <li><Link to="/customized-solutions">Customized Solutions</Link></li>
                        </ul>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6 col-sm-6 footer-segment">
                        <div className="category-title">Our address</div>
                        <ul>
                            <li>the Hive Lavender<br/>Level 6, Vanguard Building<br/>1 Kallang Junction, Singapore 339263</li>
                        </ul>
                    </div>
                    <div className="col-md-6 col-sm-6 footer-segment">
                        <div className="category-title">Connect with us</div>
                        <ul>
                            <li><a href="https://www.linkedin.com/company/mercurics/" target="_blank" rel="noopener noreferrer"><i className="icon-linkedin"></i>LinkedIn</a></li>
                            <li><b><a href="mailto:hello@mercurics.com" target="_blank" rel="noopener noreferrer"><i className="icon-mail"></i>hello@mercurics.com</a></b></li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
        <div className="copyright"><div className="container content">© 2020 Mercurics. All rights reserved.</div></div>
    </div>

export default Footer