// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-customized-solutions-js": () => import("./../../../src/pages/customized-solutions.js" /* webpackChunkName: "component---src-pages-customized-solutions-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-people-analytics-js": () => import("./../../../src/pages/people-analytics.js" /* webpackChunkName: "component---src-pages-people-analytics-js" */),
  "component---src-pages-personal-discovery-js": () => import("./../../../src/pages/personal-discovery.js" /* webpackChunkName: "component---src-pages-personal-discovery-js" */)
}

