import React from 'react'
// import PropTypes from 'prop-types'
import './LoadingSpinner.css'

const LoadingSpinner = ({ style }) =>
    <div className="loadingspinner">
        <div className="donut"></div>
    </div>

// LoadingSpinner.propTypes = {
//     style: PropTypes.object
// }

export default LoadingSpinner;