import React, { useState, useEffect, useRef } from 'react'
import { Link } from 'gatsby'
import mobileMenu from '../../images/mobile-menu.svg'
import mobileMenuAlt from '../../images/mobile-menu-alt.svg'
import SideBar from './Mobile/SideBar'

import './Header.scss'

const Header = (props) => {

    const [shrinkNav, setShrinkNav] = useState(false)

    useEffect(() => {
        window.addEventListener('scroll', handleScroll)
        return () => window.removeEventListener('scroll', handleScroll)
    }, [])

    const handleScroll = () => {
        let node = document.getElementsByTagName('section')
        if (node && node.length > 0) {
            let nodePosition = node[0].getBoundingClientRect().bottom;

            if (nodePosition < 45) setShrinkNav(true)
            else if (nodePosition >= 45) setShrinkNav(false)    
        }
    }
    
    const sidebarRef = useRef(null)
    const sidebarDivRef = useRef(null)

    useEffect(() => {
        window.addEventListener('mousedown', handleClickOutside)
        return () => window.removeEventListener('mousedown', handleClickOutside)
    }, [])

    const [showSidebar, setShowSidebar] = useState(false)

    const handleClickOutside = (e) => {
        if (!sidebarDivRef.current.contains(e.target)) {
            sidebarRef.current.slideOut()
        }
    }

    const toggleSidebar = () => {
        setShowSidebar(true)
    }

    return (
        <div>
            <div ref={sidebarDivRef}>
                <SideBar pathname={props.pathname.pathname} show={showSidebar} toggleSidebar={setShowSidebar} scrollToForm={props.scrollToForm} id="header-mobile" ref={sidebarRef} />
            </div>
            <header id="header-web" className={`${shrinkNav ? 'shrink' : props.pathname.pathname !== '/' ? 'alt' : ''}`}>
                <Link to="/" className="nav-logo">
                    <svg className="icon icon-logo-big">
                        <use xlinkHref="#icon-logo-big"></use>
                    </svg>
                    <svg className="icon icon-logo-small">
                        <use xlinkHref="#icon-logo-small"></use>
                    </svg>
                </Link>

                <ul className="nav-list">
                    <li>
                        <div className="dropdown show">
                            <span className="dropdown-text nav-item">
                                Our Solutions
                            </span>
                            <div className="tippy-arrow show"></div>
                            <div className="dropdown-menu show align-right">
                                <Link to="/personal-discovery" className='dropdown-item'>Personal Discovery</Link>
                                <div className="dropdown-divider"></div>
                                <Link to="/people-analytics" className='dropdown-item'>People Analytics for Companies</Link>
                                <div className="dropdown-divider"></div>
                                <Link to="/customized-solutions" className='dropdown-item'>Customized Solutions</Link>
                            </div>
                        </div>
                    </li>
                    <li><Link to="/about">About us</Link></li>
                    <li className="big-screen">
                        <a className="btn-highlight" onClick={() => props.scrollToForm()}>
                            <span className="highlight__background">
                                <span className="highlight__background-inner"></span>
                            </span>
                            <span className="highlight__link-text">Get in touch</span>
                        </a>
                    </li>
                    <li className="small-screen"><a>Get in touch</a></li>
                </ul>
                <div className="nav-list-bg"></div>

                <svg className="hidden">
                    <symbol id="icon-logo-small" viewBox="0 0 28 32">
                        <path d="M14.837 0.182c-0.219-0.113-0.479-0.179-0.753-0.179s-0.534 0.066-0.763 0.183l0.009-0.004-11.31 6.532c-0.187 0.062-0.319 0.234-0.319 0.438s0.132 0.377 0.316 0.437l0.003 0.001 1.531 0.893c0.221 0.113 0.483 0.179 0.759 0.179s0.538-0.066 0.769-0.183l-0.010 0.004 8.261-4.762c0.221-0.113 0.483-0.179 0.759-0.179s0.538 0.066 0.769 0.183l-0.010-0.004 9.484 5.481c0.432 0.286 0.722 0.756 0.759 1.295l0 0.005v10.979c-0.033 0.55-0.324 1.025-0.753 1.309l-0.006 0.004-9.497 5.481c-0.219 0.113-0.479 0.179-0.753 0.179s-0.534-0.066-0.763-0.183l0.009 0.004-9.505-5.481c-0.435-0.288-0.727-0.763-0.759-1.308l-0-0.005v-5.324c0-0.481 0.343-0.679 0.759-0.436l5.009 2.892c0.221 0.113 0.483 0.179 0.759 0.179s0.538-0.066 0.769-0.183l-0.010 0.004 5.974-3.45c0.404-0.242 0.759-0.044 0.759 0.436v6.964c0 0.481 0.339 0.679 0.755 0.436l1.547-0.893c0.435-0.288 0.726-0.763 0.759-1.308l0-0.005v-10.502c0-0.485-0.343-0.679-0.759-0.44l-9.036 5.223c-0.221 0.115-0.482 0.182-0.759 0.182s-0.539-0.067-0.769-0.186l0.009 0.004-8.075-4.665c-0.404-0.242-0.759-0.044-0.759 0.436v12.401c0.033 0.55 0.324 1.025 0.753 1.309l0.006 0.004 12.571 7.259c0.221 0.113 0.483 0.179 0.759 0.179s0.538-0.066 0.769-0.183l-0.010 0.004 12.562-7.271c0.435-0.288 0.727-0.763 0.759-1.308l0-0.005v-14.489c-0.033-0.549-0.325-1.024-0.753-1.309l-0.006-0.004z"></path>
                    </symbol>
                    <symbol id="icon-logo-big" viewBox="0 0 168 32">
                        <path d="M48.396 18.721v-8.523l-4.129 8.523h-2.366l-4.111-8.523v8.523h-2.348v-13.031h2.804l4.868 10.422 4.886-10.422h2.745v13.037h-2.348z"></path>
                        <path d="M52.98 18.721v-13.031h12.522v2.579h-9.967v2.881h9.683v2.1h-9.683v2.916h9.967v2.555h-12.522z"></path>
                        <path d="M78.687 18.721l-4.507-4.265h-4.111v4.265h-2.579v-13.031h10.647c0.692 0 1.278 0.071 1.751 0.219s0.858 0.379 1.147 0.692c0.29 0.314 0.503 0.733 0.633 1.248 0.13 0.521 0.201 1.142 0.201 1.875v0.799c0 0.799-0.077 1.449-0.237 1.952s-0.414 0.905-0.769 1.195c-0.355 0.29-0.81 0.491-1.366 0.597s-1.236 0.16-2.047 0.16l4.833 4.3h-3.596zM79.332 10.162c0-0.633-0.071-1.1-0.219-1.414-0.148-0.308-0.497-0.461-1.053-0.461h-7.997v4.034h7.997c0.455 0 0.781-0.112 0.976-0.343 0.195-0.225 0.296-0.627 0.296-1.195v-0.621z"></path>
                        <path d="M87.879 18.721c-0.781 0-1.431-0.071-1.952-0.207s-0.935-0.367-1.242-0.692c-0.308-0.319-0.532-0.757-0.662-1.295-0.13-0.544-0.201-1.218-0.201-2.029v-4.584c0-0.81 0.065-1.485 0.201-2.029 0.13-0.544 0.355-0.976 0.662-1.307s0.722-0.556 1.242-0.692c0.521-0.13 1.165-0.201 1.952-0.201h7.311c0.479 0 0.899 0.065 1.26 0.201 0.361 0.13 0.668 0.296 0.917 0.491 0.254 0.195 0.455 0.402 0.603 0.627 0.154 0.219 0.266 0.42 0.343 0.597l-1.627 1.668c-0.112-0.254-0.296-0.485-0.55-0.692s-0.627-0.313-1.118-0.313h-7.122c-0.568 0-0.964 0.118-1.195 0.361-0.225 0.242-0.343 0.668-0.343 1.289v4.602c0 0.278 0.018 0.521 0.059 0.722 0.035 0.201 0.112 0.373 0.219 0.509 0.106 0.142 0.26 0.242 0.461 0.314s0.467 0.106 0.799 0.106h7.216c0.503 0 0.875-0.106 1.118-0.313s0.414-0.438 0.532-0.692l1.627 1.65c-0.077 0.177-0.189 0.379-0.343 0.597s-0.355 0.432-0.603 0.627c-0.254 0.195-0.562 0.361-0.917 0.491-0.361 0.13-0.781 0.201-1.26 0.201h-7.388z"></path>
                        <path d="M104.228 18.721c-0.722 0-1.331-0.077-1.828-0.225-0.497-0.154-0.905-0.396-1.213-0.728-0.308-0.337-0.532-0.763-0.674-1.278-0.142-0.521-0.207-1.148-0.207-1.893v-8.908h2.538v8.902c0 0.556 0.106 0.958 0.314 1.201 0.207 0.248 0.568 0.367 1.071 0.367h6.365c0.503 0 0.864-0.124 1.071-0.367 0.207-0.248 0.314-0.645 0.314-1.201v-8.902h2.555v8.902c0 0.745-0.071 1.378-0.207 1.893-0.142 0.521-0.361 0.946-0.674 1.278-0.308 0.337-0.716 0.58-1.224 0.728-0.503 0.154-1.118 0.225-1.84 0.225h-6.359z"></path>
                        <path d="M127.953 18.721l-4.507-4.265h-4.111v4.265h-2.579v-13.031h10.647c0.692 0 1.278 0.071 1.751 0.219s0.858 0.379 1.148 0.692c0.29 0.314 0.503 0.733 0.633 1.248 0.13 0.521 0.201 1.142 0.201 1.875v0.799c0 0.799-0.077 1.449-0.237 1.952s-0.414 0.905-0.769 1.195c-0.355 0.29-0.81 0.491-1.366 0.597s-1.236 0.16-2.047 0.16l4.833 4.3h-3.596zM128.597 10.162c0-0.633-0.071-1.1-0.219-1.414-0.148-0.308-0.497-0.461-1.053-0.461h-7.997v4.034h7.997c0.455 0 0.781-0.112 0.976-0.343 0.195-0.225 0.296-0.627 0.296-1.195v-0.621z"></path>
                        <path d="M133.182 18.721v-13.031h2.555v13.037h-2.555z"></path>
                        <path d="M142.101 18.721c-0.781 0-1.431-0.071-1.952-0.207s-0.935-0.367-1.242-0.692c-0.308-0.319-0.532-0.757-0.662-1.295-0.13-0.544-0.201-1.218-0.201-2.029v-4.584c0-0.81 0.065-1.485 0.201-2.029 0.13-0.544 0.355-0.976 0.662-1.307s0.722-0.556 1.242-0.692c0.521-0.13 1.165-0.201 1.952-0.201h7.311c0.479 0 0.899 0.065 1.26 0.201 0.361 0.13 0.668 0.296 0.917 0.491 0.254 0.195 0.455 0.402 0.603 0.627 0.154 0.219 0.266 0.42 0.343 0.597l-1.627 1.668c-0.112-0.254-0.296-0.485-0.55-0.692s-0.627-0.313-1.118-0.313h-7.122c-0.568 0-0.964 0.118-1.195 0.361-0.225 0.242-0.343 0.668-0.343 1.289v4.602c0 0.278 0.018 0.521 0.059 0.722 0.036 0.201 0.112 0.373 0.219 0.509 0.106 0.142 0.26 0.242 0.461 0.314s0.467 0.106 0.799 0.106h7.216c0.503 0 0.875-0.106 1.118-0.313s0.414-0.438 0.532-0.692l1.627 1.65c-0.077 0.177-0.189 0.379-0.343 0.597s-0.355 0.432-0.603 0.627c-0.254 0.195-0.562 0.361-0.917 0.491-0.361 0.13-0.781 0.201-1.26 0.201h-7.388z"></path>
                        <path d="M167.985 14.953c0 0.692-0.077 1.284-0.225 1.763-0.154 0.479-0.396 0.869-0.728 1.165-0.337 0.296-0.775 0.509-1.319 0.645-0.544 0.13-1.201 0.201-1.97 0.201h-9.21c0.024-0.432 0.053-0.858 0.083-1.278 0.030-0.426 0.059-0.852 0.083-1.278h8.867c0.343 0 0.627-0.024 0.864-0.065 0.231-0.041 0.414-0.124 0.55-0.237 0.13-0.112 0.225-0.26 0.284-0.444s0.083-0.414 0.083-0.692c0-0.544-0.118-0.917-0.361-1.13-0.243-0.207-0.692-0.313-1.366-0.313h-5.276c-0.781 0-1.437-0.071-1.964-0.207-0.526-0.142-0.946-0.355-1.272-0.645-0.319-0.29-0.55-0.657-0.692-1.1s-0.207-0.958-0.207-1.556v-0.438c0-0.645 0.071-1.201 0.207-1.668s0.373-0.846 0.704-1.136c0.325-0.29 0.763-0.503 1.295-0.645 0.538-0.142 1.201-0.207 1.999-0.207h9.133c-0.053 0.444-0.101 0.875-0.154 1.295-0.053 0.426-0.106 0.858-0.172 1.295h-8.807c-0.633 0-1.059 0.106-1.278 0.319s-0.331 0.58-0.331 1.1c0 0.521 0.112 0.887 0.331 1.106s0.645 0.331 1.278 0.331h5.211c0.81 0 1.491 0.065 2.047 0.201 0.556 0.13 1.006 0.337 1.343 0.615 0.343 0.278 0.586 0.627 0.739 1.053s0.225 0.917 0.225 1.485v0.461z"></path>
                        <path d="M14.787 0.177c-0.414-0.237-1.094-0.237-1.508 0l-11.286 6.518c-0.414 0.242-0.414 0.633 0 0.875l1.544 0.887c0.414 0.242 1.094 0.242 1.514 0l8.222-4.75c0.414-0.243 1.094-0.243 1.514 0l9.464 5.465c0.414 0.242 0.757 0.828 0.757 1.307v10.931c0 0.479-0.343 1.071-0.757 1.307l-9.464 5.465c-0.414 0.242-1.094 0.242-1.514 0l-9.464-5.465c-0.414-0.242-0.757-0.828-0.757-1.307v-5.3c0-0.479 0.343-0.674 0.757-0.438l4.992 2.886c0.414 0.242 1.094 0.242 1.514 0l5.956-3.437c0.414-0.242 0.757-0.041 0.757 0.438v6.932c0 0.479 0.343 0.674 0.757 0.438l1.544-0.893c0.414-0.242 0.757-0.828 0.757-1.307v-10.47c0-0.479-0.343-0.674-0.757-0.438l-9.008 5.199c-0.414 0.242-1.094 0.242-1.514 0l-3.756-2.171c-0.414-0.242-0.757-0.438-0.757-0.438s-0.343-0.195-0.757-0.438l-2.786-1.609c-0.414-0.242-0.757-0.041-0.757 0.438v12.362c0 0.479 0.343 1.071 0.757 1.307l12.522 7.228c0.414 0.242 1.094 0.242 1.514 0l12.522-7.216c0.414-0.242 0.757-0.828 0.757-1.307v-14.462c0-0.479-0.343-1.071-0.757-1.307l-12.522-7.234z"></path>
                        <path d="M40.5 28.35v1.207h-0.84v-4.152h1.526c0.976 0 1.479 0.479 1.479 1.443s-0.503 1.502-1.479 1.502h-0.686zM41.174 27.629c0.42 0 0.627-0.254 0.627-0.781 0-0.521-0.207-0.722-0.627-0.722h-0.68v1.502h0.68z"></path>
                        <path d="M48.142 25.405h2.685v0.733h-1.845v0.97h1.479v0.722h-1.479v0.994h1.845v0.733h-2.685v-4.152z"></path>
                        <path d="M56.198 27.505c0-1.408 0.414-2.177 1.721-2.177s1.721 0.769 1.721 2.177c0 1.408-0.414 2.123-1.721 2.123s-1.721-0.716-1.721-2.123zM58.777 27.505c0-0.958-0.172-1.443-0.858-1.443s-0.858 0.485-0.858 1.443c0 0.958 0.172 1.39 0.858 1.39s0.858-0.432 0.858-1.39z"></path>
                        <path d="M66.094 28.35v1.207h-0.84v-4.152h1.526c0.976 0 1.479 0.479 1.479 1.443s-0.503 1.502-1.479 1.502h-0.686zM66.768 27.629c0.42 0 0.627-0.254 0.627-0.781 0-0.521-0.207-0.722-0.627-0.722h-0.68v1.502h0.68z"></path>
                        <path d="M73.736 29.557v-4.152h0.84v3.407h1.52v0.745h-2.36z"></path>
                        <path d="M81.508 25.405h2.685v0.733h-1.845v0.97h1.479v0.722h-1.479v0.994h1.845v0.733h-2.685v-4.152z"></path>
                        <path d="M96.597 25.405h1.603l0.935 4.152h-0.84l-0.166-0.751h-1.455l-0.166 0.751h-0.84l0.929-4.152zM96.828 28.072h1.136l-0.42-1.976h-0.296l-0.42 1.976z"></path>
                        <path d="M104.577 25.405h1.42l1.006 3.419h0.059v-3.419h0.84v4.152h-1.39l-1.035-3.419h-0.059v3.419h-0.84v-4.152z"></path>
                        <path d="M114.283 25.405h1.603l0.935 4.152h-0.84l-0.166-0.751h-1.455l-0.166 0.751h-0.84l0.929-4.152zM114.52 28.072h1.136l-0.42-1.976h-0.296l-0.42 1.976z"></path>
                        <path d="M122.262 29.557v-4.152h0.84v3.407h1.52v0.745h-2.36z"></path>
                        <path d="M131.188 29.557h-0.84v-1.68l-1.284-2.472h0.935l0.763 1.656 0.763-1.656h0.935l-1.272 2.472v1.68z"></path>
                        <path d="M137.434 25.405h3.052v0.745h-1.1v3.407h-0.846v-3.407h-1.112v-0.745z"></path>
                        <path d="M145.905 25.405h0.84v4.152h-0.84v-4.152z"></path>
                        <path d="M155.173 29.492c-0.278 0.059-0.828 0.142-1.207 0.142-1.284 0-1.585-0.698-1.585-2.147 0-1.573 0.355-2.147 1.585-2.147 0.432 0 0.958 0.101 1.218 0.166l-0.024 0.674c-0.284-0.041-0.793-0.089-1.071-0.089-0.645 0-0.84 0.225-0.84 1.408 0 1.118 0.148 1.402 0.869 1.402 0.278 0 0.799-0.047 1.047-0.083l0.006 0.674z"></path>
                        <path d="M163.241 26.162c0 0-0.864-0.101-1.218-0.101-0.45 0-0.668 0.166-0.668 0.438 0 0.26 0.166 0.355 0.864 0.574 0.899 0.29 1.171 0.538 1.171 1.23 0 0.905-0.615 1.325-1.437 1.325-0.568 0-1.384-0.189-1.384-0.189l0.083-0.651c0 0 0.816 0.112 1.254 0.112 0.455 0 0.651-0.213 0.651-0.538 0-0.254-0.148-0.373-0.745-0.544-0.935-0.266-1.289-0.586-1.289-1.254 0-0.864 0.574-1.236 1.42-1.236 0.556 0 1.366 0.166 1.366 0.166l-0.065 0.668z"></path>
                    </symbol>
                </svg>
            </header>
            <header id="header-mobile" className={`${shrinkNav ? 'shrink' : props.pathname.pathname !== '/' ? 'alt' : ''}`}>
                <Link to="/" className="nav-logo">
                    <svg className="icon icon-logo-big">
                        <use xlinkHref="#icon-logo-big"></use>
                    </svg>
                </Link>
                <img src={mobileMenu} onClick={toggleSidebar} className="org" />
                <img src={mobileMenuAlt} onClick={toggleSidebar} className="alt" />
            </header>
        </div>

    )
}

export default Header