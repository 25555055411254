export function scrollToNode(node) {
    // node.scrollIntoView({ behavior: 'smooth' })
    const headerOffset = 30;
    const nodePosition = node.getBoundingClientRect().top;
    const offsetPosition = nodePosition - headerOffset;

    window.scrollTo({
         top: offsetPosition + window.scrollY,
         behavior: "smooth"
    });
}

export function scrollToDemo(node) {
    const headerOffset = 150;
    const nodePosition = node.getBoundingClientRect().bottom;
    const offsetPosition = nodePosition - headerOffset;

    window.scrollTo({
         top: offsetPosition + window.scrollY,
         behavior: "smooth"
    });
}