import React, { Component } from 'react'
import { Link } from 'gatsby'
import backArrow from '../../../images/sidebar-back.svg'
import './SideBar.scss'
import { scrollToNode } from '../../../helpers/scrollToNode'

class SideBar extends Component {

    state = {
        isSlidingOut: false
    }

    getInTouchHandler = () => {
        const { pathname } = this.props
        let node
        
        this.slideOut()
		if (pathname === '/') {
			node = document.getElementById('contact-us-mobile')
		} else {
			node = document.getElementById('contact-form-mobile')
        }
        setTimeout(() => scrollToNode(node), 300)
    }

    slideOut = () => {
        const { show, toggleSidebar } = this.props

        if (show) {
            this.setState({isSlidingOut: true}, 
                () => setTimeout(() => {
                    this.setState({isSlidingOut: false})
                    toggleSidebar(false)
                }, 500))
        }
    }

    render() {

        const { pathname, show } = this.props

        return (
            <div id="sidebar" className={"sidebar " + (show ? this.state.isSlidingOut ? 'slide-out' : '' : 'hidden')}>
                <div className="container content">
                    <div className="row">
                        <div className="col-12">
                            <img src={backArrow} className="back-arrow" onClick={this.slideOut} alt="" />
                        </div>
                    </div>
                    <div className="row" style={{marginTop:'-9%'}}>
                        <div className="col-12">
                            <div className="menu">
                                <span className="text-gray-light">OUR SOLUTIONS</span>
                                <ul>
                                    <li><Link to="/personal-discovery" className={ pathname === '/personal-discovery' ? 'selected' : '' } onClick={this.slideOut} ><span className="dot"></span>Personal Discovery</Link></li>
                                    <li><Link to="/people-analytics" className={ pathname === '/people-analytics' ? 'selected' : '' } onClick={this.slideOut}><span className="dot"></span>People Analytics for Companies</Link></li>
                                    <li><Link to="/customized-solutions" className={ pathname === '/customized-solutions' ? 'selected' : '' } onClick={this.slideOut}><span className="dot"></span>Customized Solutions</Link></li>
                                    <li style={{marginTop:'2.5rem'}} ><Link to="/" className={ pathname === '/' ? 'selected' : '' } onClick={this.slideOut}><span className="dot"></span>Home</Link></li>
                                    <li><Link to="/about" className={ pathname === '/about' ? 'selected' : '' } onClick={this.slideOut}><span className="dot"></span>About us</Link></li>
                                    <li><a onClick={this.getInTouchHandler} ><span className="dot"></span>Get in touch</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container menu-bottom">
                    <p><b>Connect with us</b></p>
                    <a href={'https://www.linkedin.com/company/mercurics/'} target="_blank" rel="noopener noreferrer">LinkedIn <i className="icon-linkedin"></i></a><br/>
                    <a href="mailto:hello@mercurics.com" target="_blank" rel="noopener noreferrer">hello@mercurics.com <i className="icon-mail"></i></a>
                </div>
            </div>
        )}
}

export default SideBar