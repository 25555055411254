import React, { useState } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { motion, AnimatePresence } from 'framer-motion'

import Header from "./Header"
import Footer from "./Footer"
import ContactUsForm from "../components/ContactUsForm"
import debounce from "../helpers/debounce"
import "./layout.scss"

import { scrollToNode } from '../helpers/scrollToNode'


const Layout = ({ children, location }) => {

	const [showScrollToTopBtn, setShowScrollToTopBtn] = useState(false)

	const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

	React.useEffect(() => {
		const handleScroll = debounce(() => {
			if ((window.scrollY > window.innerHeight * 0.3) && !showScrollToTopBtn)
				setShowScrollToTopBtn(true)
			else if ((window.scrollY < window.innerHeight * 0.3) && showScrollToTopBtn)
				setShowScrollToTopBtn(false)
		}, 250)

		window.addEventListener('scroll', handleScroll)
		return () => window.removeEventListener('scroll', handleScroll)
	}, [])
	
    const scrollToForm = () => {
		let node
		
		if (location.pathname === '/') {
			node = document.getElementById('contact-us-web')
		} else {
			node = document.getElementById('contact-form-web')
		}
		scrollToNode(node)
	}

	return (
		<div className="layout-wrapper">

			<Header siteTitle={data.site.siteMetadata.title} pathname={location} scrollToForm={scrollToForm} />

			<AnimatePresence>
				<motion.main
					className="main-content"
					key={location.pathname}
					variants={mainVariants}
					initial="initial"
					animate="enter"
					exit="exit"
				>
					{children}
				</motion.main>
			</AnimatePresence>
			{ 
				location.pathname === '/' ? '' 
				: 
				<>
					<section className="bg--quaternary" id="contact-form-web">
						<div className="container content">
							<div className="row" style={{paddingTop:'3rem'}}>
								<div className="col-sm-5 col-md-5 hidden-opp-container">
									<h1 className="section-title-black">Hidden opportunities abound</h1>
									<p className="description">Find out how People Analytics can help your organization today. Drop your questions in the form and we'll get back to you soon.</p>
								</div>
								<div className="col-sm-7 col-md-7">
									<ContactUsForm />
								</div>
							</div>
						</div>
					</section>
					<section className="bg--quaternary" id="contact-form-mobile">
						<div className="container content">
							<div className="row" style={{paddingTop:'2rem'}}>
								<div className="col-12">
									<h1 className="section-title-secondary-black">Hidden opportunities abound</h1>
									<p>Find out how People Analytics can help your organization today. Drop your questions in the form and we'll get back to you soon.</p>
								</div>
								<div className="col-12">
									<ContactUsForm />
								</div>
							</div>
						</div>
					</section>
				</>
			}
			<Footer />

			<button 
				className={`go-back-up ${showScrollToTopBtn ? '' : 'invisible'}`} 
				onClick={() => window.scroll({
					top: 0,
					behavior: 'smooth'
				})}>
				<span className="icon-up-open"></span>
			</button>

			<svg className="hidden">
				<symbol id="icon-location" viewBox="0 0 32 32">
					<path d="M11.163 11.554c0-2.655 2.166-4.807 4.837-4.807s4.837 2.152 4.837 4.807-2.166 4.806-4.837 4.806-4.837-2.152-4.837-4.806zM7.777 12.154c0 2.011 2.454 6.25 2.454 6.25l5.769 9.614 5.438-9.613c0 0 2.785-4.27 2.785-6.25 0-4.513-3.682-8.171-8.223-8.171s-8.223 3.657-8.223 8.17z"></path>
				</symbol>
			</svg>
		</div>
	)
}

const duration = 0.4

const mainVariants = {
	initial: {
		opacity: 0,
	},
	enter: {
		opacity: 1,
		transition: {
			duration: duration,
			delay: duration,
			when: 'beforeChildren',
		},
	},
	exit: {
		opacity: 0,
		transition: { duration: duration },
	},
}

Layout.propTypes = {
	children: PropTypes.node.isRequired,
}

export default Layout