import React, { Component } from 'react'
import './ContactUsForm.scss'
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner'
import Tick from '../../images/tick.svg'

class ContactUsForm extends Component {

    state = {
        name: '',
        email: '',
        message: '',
        email_is_valid: false,
        status: false,
        error_msg: '',
        loading: false,
        show_tick: false
    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        }, () => this.validateEmail(this.state.email))
    }

    validateEmail = (email) => {
        this.setState({
            email_is_valid: email.match(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/) ? true : false
        })
    }

    handleSubmit = () => {

        if (!this.state.name || this.state.name === '') {
            this.setState({ status: 'failed', error_msg: 'Please enter your name.' })
        }
        else if (!this.state.email_is_valid) {
            this.setState({ status: 'failed', error_msg: 'Please enter a valid email.' })
        }
        else {
            const requestOptions = {
                method: 'POST',
                headers: { 
                    'Content-Type': 'application/json',
                    'x-api-key': `${process.env.AWS_LAMBDA_API_KEY}`
                },
                body: JSON.stringify(
                    {
                        "name": this.state.name,
                        "email": this.state.email,
                        "message": this.state.message
                    }
                )
            }
            this.setState({ loading: true, status: false, error_msg: '' })

            fetch('https://msicuh8nv9.execute-api.ap-southeast-2.amazonaws.com/default/sendContactUsForm', requestOptions)
            .then(response => response.json())
            .then(response => {
                this.setState({ status: response.status }, 
                    this.setState({ 
                        loading: false, 
                        show_tick: true,
                        name: '',
                        email: '',
                        message: '', 
                    }, () => setTimeout(() => {
                            this.setState({ show_tick: false })
                        }, 3000)
                    )
                )},
            error => {
                this.setState({ status: 'failed', error_msg: 'Sorry, we have failed to receive your message. Try again, or contact us at hello@mercurics.com.' }, this.setState({ loading: false }))
            })
        }
    }

    render() {
        
        const { showText } = this.props

        return (
            <div className="contact-form">
                { showText ? <p>Our team is here to answer any questions you have about our products and services. Drop your questions below and we'll get back to you soon.</p> : ''}
                <input type="text" name="name" placeholder="Name" onChange={this.handleChange} value={this.state.name} /><span className="required">*</span>
                <input type="email" name="email" placeholder="your@email.com" onChange={this.handleChange} value={this.state.email} /><span className="required">*</span>
                <textarea name="message" placeholder="Message" onChange={this.handleChange} value={this.state.message} />
                {
                    this.state.status ? 
                        this.state.status == 'success' ?  
                            <span className={"status-success"}>Message received. We will get back to you soon.</span>
                        : <span className={"status-failed"}>{this.state.error_msg}</span>
                    : ''
                }
                <button className={"btn btn-primary js-trigger animation-element mobile-align-right " + (this.state.show_tick ? "success" : "")} 
                    onClick={this.handleSubmit} disabled={this.state.show_tick || this.state.loading} >
                    { this.state.loading ? <LoadingSpinner/> : this.state.show_tick ? <img src={Tick} /> : "Send Message"}
                </button>
            </div>)
    }

}

export default ContactUsForm